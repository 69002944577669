import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import requestClient from '../../utils/requestClient';
import { pillarMapper } from '../../utils/pillarMapper';
import ErrorMessage from '../../components/Elements/ErrorMessage';
import ProgressBar from '../../components/Elements/ProgressBar';
import FormTextArea from '../../components/Elements/Form/FormTextArea';
import FormCheckbox from '../../components/Elements/Form/FormCheckbox';

const Questionnaire = () => {
    const { id } = useParams();

    const [errors, setErrors] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);

    const [data, setData] = useState(null);
    const [questions, setQuestions] = useState({});
    const [answers, setAnswers] = useState({});

    const updateAnswers = (id, key, value) => {
        let newAnswers = { ...answers };

        newAnswers[id][key] = value

        setAnswers(newAnswers)
    }

    const addSentimentWords = (id, value) => {
        let newAnswers = { ...answers };

        newAnswers[id]['sentiment_words'].push(value)

        setAnswers(newAnswers);
    }

    const removeSentimentWords = (id, value) => {
        let newAnswers = { ...answers };

        const index = newAnswers[id]['sentiment_words'].indexOf(value)
        newAnswers[id]['sentiment_words'].splice(index, 1)

        setAnswers(newAnswers);
    }

    useEffect(() => {
        async function getData() {
            const response = await requestClient().get(`/api/v2/questionnaire/${id}/`)

            let answers = {}

            // Map questions to accepted response format
            response.data.data.questions.forEach(r => {
                answers[r.id] = {
                    'id': r.id,
                    'response': '',
                    'response_type': r.response_type,
                    'sentiment_free_text': null,
                    'sentiment_words': []
                }
            })

            let questions = {}

            // Map questions by pillar
            response.data.data.questions.forEach(question => {
                const pillar = question.pillar ? question.pillar : 'other'

                if (!(pillar in questions)) {
                    questions[pillar] = []
                }

                questions[pillar].push(question)
            })

            setAnswers(answers)
            setQuestions(questions)
            setData(response.data.data)
        }
        getData();
    }, []);

    function changeStep(event, step) {
        event.preventDefault()
        setCurrentStep(step)
    }

    async function handleSubmit(event) {
        event.preventDefault()

        setErrors(null)

        await requestClient().post(`/api/v2/questionnaire/${id}/`, { 'questions': Object.values(answers) })
            .then(response => {
                if (response.status === 201) {
                    // Send self assessment to results page
                    if (data.self_assessment === true) {
                        window.location.href = '/questionnaire/results'
                    }
                    else {
                        window.location.href = '/'
                    }
                }
            })
            .catch(error => {
                setErrors(error.response.data.error)
            })
    }

    const progressPercentage = (index) => {
        return Math.min(((index + 1) / Object.keys(questions).length) * 100)
    }

    const ScaleQuestion = ({ question, answer }) => {
        const scaleValues = [
            {
                label: 1,
                value: 0
            },
            {
                label: 2,
                value: 25
            },
            {
                label: 3,
                value: 50
            },
            {
                label: 4,
                value: 75
            },
            {
                label: 5,
                value: 100
            }
        ]

        return (
            <div>
                <div className='mb-6'>
                    <h3 className="h4">{question.title}</h3>
                    {question.subtitle ? (<p className='prose mt-2'>{question.subtitle} </p>) : null}
                </div>
                {question.min_label !== '1' && question.max_label !== '5' ?
                    (<div className="flex justify-between mb-4">
                        <span className='text-sm'>{question.min_label}</span>
                        <span className="text-sm">{question.max_label}</span>
                    </div>) : null}
                <div className="grid grid-cols-5 gap-4 lg:gap-8">
                    {scaleValues.map((value, index) => (
                        <label key={index} className={`transition-all appearance-none z-10 relative flex items-center border border-4 text-xl lg:text-3xl justify-center rounded-full aspect-square cursor-pointer hover:bg-pink-light hover:border-purple :invalid:bg-blue ${answer.response === value.value ? 'border-purple bg-pink-light font-bold' : 'border-white bg-white'}`}>
                            <input type="radio" checked={answer.response === value.value} name={`question_${question.id}_scale`} required
                                onChange={() => updateAnswers(question.id, 'response', value.value)} className='appearance-none absolute h-0 w-0 border-transparent focus:ring-0' />
                            <span>{value.label}</span>
                        </label>
                    ))}
                </div>
            </div>
        );
    };

    const BooleanQuestion = ({ question, answer }) => {
        return (
            <div>
                <div className='mb-6'>
                    <h3 className="h4">{question.title}</h3>
                    {question.subtitle ? (<p className='prose mt-2'>{question.subtitle} </p>) : null}
                </div>
                <div className="grid grid-cols-5 gap-4 lg:gap-8">
                    <label className={`transition-all appearance-none z-10 relative flex items-center border border-4 text-xl lg:text-3xl justify-center rounded-full aspect-square cursor-pointer hover:bg-pink-light hover:border-purple :invalid:bg-blue ${answer.response === true ? 'border-purple bg-pink-light font-bold' : 'border-white bg-white'}`}>
                        <input type="radio" checked={answer.response === true} name={`question_${question.id}_boolean`} required
                            onChange={() => updateAnswers(question.id, 'response', true)} className='appearance-none absolute h-0 w-0 border-transparent focus:ring-0' />
                        <span>Yes</span>
                    </label>
                    <label className={`transition-all appearance-none z-10 relative flex items-center border border-4 text-xl lg:text-3xl justify-center rounded-full aspect-square cursor-pointer hover:bg-pink-light hover:border-purple :invalid:bg-blue ${answer.response === false ? 'border-purple bg-pink-light font-bold' : 'border-white bg-white'}`}>
                        <input type="radio" checked={answer.response === false} name={`question_${question.id}_boolean`} required
                            onChange={() => updateAnswers(question.id, 'response', false)} className='appearance-none absolute h-0 w-0 border-transparent focus:ring-0' />
                        <span>No</span>
                    </label>
                </div>
            </div>
        );
    };

    const WordsSentiment = ({ question, answer }) => {
        return (
            <div>
                 <div className='mb-6'>
                    <h3 className="h4">{question.title}</h3>
                    {question.subtitle ? (<p className='prose mt-2'>{question.subtitle} </p>) : null}
                </div>
                {question.min_label !== '1' && question.max_label !== '5' ?
                    (<div className="flex justify-between mb-4">
                        <span className='text-sm'>{question.min_label}</span>
                        <span className="text-sm">{question.max_label}</span>
                    </div>) : null}

                <div className="grid grid-cols-4 gap-4 lg:gap-8">
                    {question.sentiment_words.map((word, index) => (
                        <div>
                            <label key={index} className={`transition-all appearance-none z-10 relative flex items-center border border-4 text-xl lg:text-3xl justify-center rounded-full aspect-square cursor-pointer hover:bg-pink-light hover:border-purple :invalid:bg-blue ${answer.sentiment_words.includes(word.id)? 'border-purple bg-pink-light font-bold' : 'border-white bg-white'}`}>
                                <input type="radio" checked={answer.sentiment_words.includes(word.id)} name={`question_${question.id}_scale`} required
                                    onChange={(event) => event.target.checked ? addSentimentWords(question.id, word.id) : removeSentimentWords(question.id, word.id)} className='appearance-none absolute h-0 w-0 border-transparent focus:ring-0' />
                                <span dangerouslySetInnerHTML={{__html: word.icon}} className='text-[50px]'></span>
                            </label>
                            <span className='block text-center font-bold mt-3'>{word.word}</span>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const pillar = pillarMapper(Object.keys(questions)[currentStep])

    return (data && questions ?
        <main>
            <section>
                <div className='container py-8 lg:py-12 max-w-[768px]'>
                    {pillar ? pillar.icon("max-w-[80px] mb-4") : null}
                    <div className='mb-4'>
                        <h1>{data.title}</h1>
                        {data.description && data.title !== data.description ? (<p className='mt-1 text-sm'>{data.description}</p>) : null}
                    </div>
                    <ProgressBar progress={progressPercentage(currentStep)} className="mb-6 lg:mb-12" />
                    {errors ? <ErrorMessage title="Something is not right" errors={errors} className="mb-4" /> : null}
                    {Object.keys(questions).map((pillar, index) => (
                        <form key={pillar} className={currentStep === index ? 'block' : 'hidden'}
                            onSubmit={(event) => currentStep === Object.keys(questions).length - 1 ? handleSubmit(event) : changeStep(event, currentStep + 1)}>
                            <div className='grid gap-6 lg:gap-12'>
                                {questions[pillar].map(question => (
                                    <div key={question.id} className='grid gap-4'>
                                        {question.response_type === 'scale' ? <ScaleQuestion question={question} answer={answers[question.id]} /> : null}
                                        {question.response_type === 'boolean' ? <BooleanQuestion question={question} answer={answers[question.id]} /> : null}
                                        {question.response_type === 'free_text' ? (
                                            <div>
                                                <div className='mb-6'>
                                                    <h3 className="h4">{question.title}</h3>
                                                    {question.subtitle ? (<p className='prose mt-2'>{question.subtitle} </p>) : null}
                                                </div>
                                                <div>
                                                    <FormTextArea name={`question_${question.id}_free_text`} value={answers[question.id].response} onChange={(event) => updateAnswers(question.id, 'response', event.target.value)} />
                                                </div>
                                            </div>
                                        ) : null}
                                        {question.allows_free_text_sentiment_response ? (
                                            <div>
                                                <FormTextArea name={`question_${question.id}_sentiment_free_text`} label="Type your feelings below:" value={answers[question.id].sentiment_free_text} onChange={(event) => updateAnswers(question.id, 'sentiment_free_text', event.target.value)} />
                                            </div>
                                        ) : null}
                                        {question.sentiment_words && question.sentiment_words.length > 0 ? (<WordsSentiment question={question} answer={answers[question.id]} />) : null}
                                    </div>
                                ))}
                            </div>
                            <div className="flex justify-between mt-6 lg:mt-12">
                                {currentStep > 0 ? (<button onClick={() => setCurrentStep(currentStep - 1)} className="btn btn-invert w-full flex justify-center lg:max-w-[250px]">Back</button>) : null}
                                <button className="btn btn-purple w-full flex justify-center lg:ml-auto lg:max-w-[250px]">{currentStep === Object.keys(questions).length - 1 ? 'FINISH' : 'NEXT'}</button>
                            </div>
                        </form>
                    ))}
                </div>
            </section>
        </main>
        : null);
};

export default Questionnaire;