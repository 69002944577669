import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import requestClient from '../../utils/requestClient';
import {resourceMapper} from '../../utils/resourceMapper';
import ArticleLists from '../../components/Sections/ArticleLists';

const Favourites = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        async function getData() {
            const response = await requestClient().get('/api/v2/favourites/')
            
            let articles = {}
        
            response.data.data.map(article => {
                if(!(article.item.content_type in articles )){
                    articles[article.item.content_type] = {
                        title: resourceMapper(article.item.content_type).title,
                        articles:  []
                    }
                }
          
                articles[article.item.content_type].articles.push(article.item)
            })
            
            setData(articles);
        }
        getData();
     }, []);

    return (data ?
        <main>
            <section className="bg-white">
                <div className='container py-8 lg:py-12'>
                    <div className='border-b pb-8'>
                        <h1>Favourites</h1>
                    </div>
                    <ArticleLists data={data} />
                 </div>
            </section>
        </main>
    : null);
};

export default Favourites;
