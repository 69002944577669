import React from 'react';
import { Link } from 'react-router-dom';

import { resourceUrl } from '../../../utils/resourceMapper';
import Image from '../../../components/Elements/Image';

const ArticleCardLarge = ({ item }) => {
    const url = resourceUrl(item)
    let imageUrl = 'https://place-hold.it/300x200'

    if ('thumbnail' in item && item.thumbnail) {
        imageUrl = item.thumbnail
    }
    else if ('image' in item  && item.image) {
        imageUrl = item.image
    }

    return (
        <div>
            <Link to={url}>
                <Image
                    src={imageUrl} alt={item.title} className="w-full rounded-xl lg:rounded-2xl aspect-video object-cover"
                />
            </Link>
            <div className='mt-4'>
                <h3 className="h4 mb-2">{item.title}</h3>
                <Link
                    to={url}
                    className="btn btn-invert block lg:inline-block">
                    View
                </Link>
            </div>
        </div>
    );
};

export default ArticleCardLarge;