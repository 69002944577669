import React, { useContext, useState, useEffect } from 'react';
import { Link, redirect } from 'react-router-dom';

import { BookmarkIcon } from '../../components/Icons';

import requestClient from '../../utils/requestClient';
import { resourceMapper } from '../../utils/resourceMapper';
import { months } from '../../utils/dateUtils';
import { resourceUrl } from '../../utils/resourceMapper';
import Tooltip from '../../components/Elements/Tooltip';
import Histogram from '../../components/Elements/Reporting/Histogram';
import Loader from '../../components/Elements/Loader';
import ArticleLists from '../../components/Sections/ArticleLists';
import ArticleGrid from '../../components/Sections/ArticleGrid';
import ArticleCardSentiment from '../../components/Elements/Cards/ArticleCardSentiment';
import Image from '../../components/Elements/Image';
import { CurrentUser } from '../../App';

const Dashboard = () => {
    const user = useContext(CurrentUser)
    const now = new Date();
    const month2 = new Date()
    const month3 = new Date()

    month2.setMonth(now.getMonth() - 1)
    month3.setMonth(now.getMonth() - 2)

    const monthNames = [
        months[now.getMonth()],
        months[month2.getMonth()],
        months[month3.getMonth()],
    ]

    const [data, setData] = useState(null);
    const [articles, setArticles] = useState(null);
    const [currentMonth, setCurrentMonth] = useState(1);
    const [selfReflection, setSelfReflection] = useState({});

    const updateSelfReflection = (id, value) => {
        let newSelfReflection = { ...selfReflection };
        newSelfReflection[id] = value
        setSelfReflection(newSelfReflection);
    }

    const getSelfReflectionData = async (month) => {
        if (!(month in selfReflection)) {
            const response = await requestClient().get(`/api/v2/self-reflection?month=${month}`)
            updateSelfReflection(month, response.data.data)
        }

        setCurrentMonth(month)
    }

    useEffect(() => {
        async function getData() {
            const response = await requestClient().get('/api/v2/dashboard/')

            // If we have questionaires then lets redirect the user
            if (response.data.data.questionnaires.length > 0) {
                const questionnaires = response.data.data.questionnaires
                window.location.href = `/questionnaire/${questionnaires[0]}`
                return
            }

            let articles = {}

            response.data.data.related_content.map(article => {
                if (!(article.content_type in articles)) {
                    articles[article.content_type] = {
                        title: resourceMapper(article.content_type).title,
                        articles: []
                    }
                }

                articles[article.content_type].articles.push(article)
            })

            setArticles(articles);
            setData(response.data.data);
        }
        getData();
    }, []);

    useEffect(() => {
        async function getSelfReflection() {
            await getSelfReflectionData(currentMonth)
        }
        getSelfReflection();
    }, []);

    const SelectWorkskop = () => {
        return (
            <div>
                <span className='text-sm block mb-1'>WORKSHOPS</span>
                <h3 className='text-white mb-3'>Earn More Points</h3>
                <p className='mb-6'>Select from the list of wellbeing workshops. Your wellbeing results card can help guide you towards which of the Six Pillars to focus on this month.</p>
                <Link to="/workshops" className="btn btn-invert block bg-white border-none">SELECT WORKSHOP</Link>
            </div>
        );
    };

    const WorkshopSummary = (workshop) => {
        return (
            <div>
                <span className='text-sm block mb-1'>WORKSHOPS</span>
                <h3 className='h4 text-white mb-3'>{workshop.goal.goal.video.title}</h3>
                <Image src={workshop.goal.goal.video.image} className="w-full rounded-xl aspect-video object-cover mb-6" />
                {/* <p className='mb-6 whitespace'>{workshop.goal.video.description}</p> */}
                {workshop.goal.is_complete ?
                    <span className="btn bg-grey text-black block w-full">Complete</span> :
                    <Link to={resourceUrl(workshop.goal.goal.video)} className="btn btn-invert block bg-white border-none">VIEW WORKSHOP</Link>
                }
            </div>
        );
    };

    return (data ?
        <main>
            <section className='mb-8'>
                <div className="container grid grid-cols-12 gap-8">
                    <div className="col-span-12 bg-white p-8 lg:col-span-8">
                        <div className="flex mb-6 gap-6 items-center  flex-col lg:flex-row lg:justify-between">
                            <div className='flex items-center gap-2'>
                                <h3>My Wellbeing Scorecard</h3>
                                <Tooltip text="Track how you are performing against the pilars of Mind, Body, Career, Surrounding, Relationships and Money." />
                            </div>
                            <div className="flex items-center">
                                <button className={`transition-all w-[40px] h-[40px] flex items-center justify-center text-black bg-grey-light rounded-full hover:bg-purple hover:text-white ${currentMonth === monthNames.length ? 'opacity-50' : ''}`} disabled={currentMonth === monthNames.length} onClick={() => getSelfReflectionData(currentMonth + 1)}>&lt;</button>
                                <span className="font-bold mx-6">{monthNames[currentMonth - 1]}</span>
                                <button className={`transition-all w-[40px] h-[40px] flex items-center justify-center text-black bg-grey-light rounded-full hover:bg-purple hover:text-white ${currentMonth === 1 ? 'opacity-50' : ''}`} disabled={currentMonth === 1} onClick={() => getSelfReflectionData(currentMonth - 1)}>&gt;</button>
                            </div>
                        </div>
                        {currentMonth in selfReflection ? (<Histogram histogramData={selfReflection[currentMonth]} className={'lg:grid-cols-2'} />) : null}
                    </div>

                    <aside className="col-span-12 bg-purple-light text-white p-8 lg:col-span-4">
                        {user.user.active_goal ? <WorkshopSummary goal={user.user.active_goal} /> : <SelectWorkskop />}
                    </aside>
                </div>
            </section>
            {data.content.length > 0 ? (<ArticleGrid title='Your recommended content' articles={data.content} className='bg-white' />) : null}
            {data.sentiment_content.length > 0 ? (
                <section>
                    <div className='container py-8 lg:py-12'>
                        <div className='flex items-center justify-between mb-8 gap-4 flex-col lg:flex-row border-b pb-8 '>
                            <h2 className='max-w-[600px]'>Emotional Resources</h2>
                        </div>
                        <div className='grid gap-8 lg:grid-cols-2'>
                            {data.sentiment_content.map(article => (
                                <ArticleCardSentiment key={article.id} item={article} />
                            ))}
                        </div>
                    </div>
                </section>) : null}
            {data.employer_content.length > 0 ? (<ArticleGrid title='Company Resources' articles={data.employer_content} />) : null}
            <section className={data.employer_content.length > 0 ? 'bg-white' : ''}>
                <div className='container py-8 lg:py-12'>
                    <div className='flex items-center justify-between gap-4 flex-col lg:flex-row border-b pb-8'>
                        <h2>Other content</h2>
                        <Link to="/favourites" className='btn btn-invert w-full flex justify-center lg:w-auto'>
                            <BookmarkIcon />
                            <span className="ml-2">View favourites</span>
                        </Link>
                    </div>
                    {data ? (<ArticleLists data={articles} />) : null}
                </div>
            </section>
        </main>
        : <Loader className="my-8" text="Loading your dashboard" />);
};

export default Dashboard;
