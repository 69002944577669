import React, { useState, useEffect } from 'react';

import requestClient from '../../utils/requestClient';
import {resourceMapper} from '../../utils/resourceMapper';
import ArticleLists from '../../components/Sections/ArticleLists';

const Resources = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        async function getData() {
            const response = await requestClient().get('/api/v2/resources/overview')

            let articles = {}
        
            response.data.data.map(article => {
                if(!(article.content_type in articles )){
                    articles[article.content_type] = {
                        title: resourceMapper(article.content_type).title,
                        articles:  []
                    }
                }
          
                articles[article.content_type].articles.push(article)
            })
            
            setData(articles);
        }
        getData();
     }, []);

    return (data ?
        <main>
            <section className="bg-white">
                <div className='container py-8 lg:py-12'>
                    <div className='border-b pb-8'>
                        <h1>Resources</h1>
                    </div>
                    <ArticleLists data={data} />
                 </div>
            </section>
        </main>
    : null);
};

export default Resources;
