import React from 'react';

import ArticleCardLarge from '../../components/Elements/Cards/ArticleCardLarge';

const ArticleGrid = ({ title, articles, className = '' }) => {
    return (
        <section className={`${className}`}>
            <div className='container py-8 lg:py-12'>
                <div className='flex items-center justify-between mb-8 gap-4 flex-col lg:flex-row border-b pb-8 '>
                    <h2 className='max-w-[600px]'>{title}</h2>
                </div>
                <div className='grid gap-8 lg:grid-cols-2'>
                    {articles.map(article => (
                        <ArticleCardLarge key={article.id} item={article} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default ArticleGrid;