import React from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const NewsletterClicksChart = ({ data }) => {
    const options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        tooltip: {
            pointFormat: '<b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false
                },
                showInLegend: true
            }
        },
        title: null,
        series: [{
            colorByPoint: true,
            data: [{
                color: '#77BB0E',
                name: 'Click Rate',
                y: data.click_rate ? data.click_rate : 0,
            },
            {
                color: '#EC4A18',
                name: 'Bounce Rate',
                y: data.bounce_rate ? data.bounce_rate : 0,
            }]
        }],
        credits: {
            enabled: false
        }
    }

    return (
        <HighchartsReact highcharts={Highcharts} options={options} />
    );
};

export default NewsletterClicksChart;