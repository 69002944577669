const resourceMap = {
    "video": {
        'title': 'Workshops',
        'site_url': '/resources/{content_type}/{id}',
        'rating': true,
        'favourite': true,
    },
    "relax": {
        'title': 'Relaxation',
        'site_url': '/resources/{content_type}/{id}',
        'rating': true,
        'favourite': true,
    },
    "article": {
        'title': 'Exercise',
        'site_url': '/resources/{content_type}/{id}',
        'rating': true,
        'favourite': true,
    },
    "recipe": {
        'title': 'Recipies',
        'site_url': '/resources/{content_type}/{id}',
        'rating': true,
        'favourite': true,
    },
    "newsletter": {
        'title': 'Blogs',
        'site_url': '/resources/{content_type}/{id}',
        'rating': false,
        'favourite': false,
    },
    'questionnaire': {
        'title': 'TBC',
        'site_url': '/resources/{content_type}/{id}',
        'rating': false,
        'favourite': false,
    },
    'self_assessment': {
        'title': 'TBC',
        'site_url': '/resources/{content_type}/{id}',
        'rating': false,
        'favourite': false,
    },
    'employer': {
        'title': 'Resource',
        'site_url': '/resources/{content_type}/{id}',
        'rating': false,
        'favourite': false,
    }
}

export const resourceMapper = (resourceType) => {
    return resourceMap[resourceType]
}

export const resourceUrl = (resource) => {
    let mappedResource = resourceMap[resource.content_type]
    let site_url = mappedResource.site_url

    site_url = site_url.replace('{id}', resource.id).replace('{content_type}', resource.content_type)

    return site_url
}